import { avatars } from './avatar-data'
import axios from 'axios'

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

const bytesToKB = (bytes, decimals = 3) => {
  const dm = decimals < 0 ? 0 : decimals
  return parseFloat((bytes / 1024).toFixed(dm)) ?? ''
}

const replaceLineBreaks = (text) => {
  return text ? text.replace(/(?:\\r\\n|\\r|\\n)/g, '<br />') : ''
}

const getAvatarImageById = (id) => {
  const img = avatars.find((img) => img.id === id)
  if (img) return img.src
  return ''
}

const validVideoType = (file) => {
  const fileTypes = [
    'video/webm',
    'video/avi',
    'video/m4v',
    'video/x-matroska',
    'video/mpeg',
    'video/3gpp',
    'video/vob',
    'video/mp4',
    'video/mxf',
    'video/x-ms-wmv',
    'video/quicktime'
  ]
  return fileTypes.includes(file.type)
}

const setUserName = (name) => {
  return name
    ? name
      .split('')
      .filter((val) => val !== ',')
      .join('')
    : ''
}

const awaitTimeout = (delay) =>
  new Promise((resolve) => setTimeout(resolve, delay))

const runSpeedTest = () => {
  const data = new TextEncoder().encode('1234567890123456789012345678901234567890')
  const startTime = new Date()

  // Send the file to a remote server
  const url = 'https://jsonplaceholder.typicode.com/posts'

  return axios.post(url, data).then(() => {
    const endTime = new Date()

    // Get the upload time
    const uploadTime = (endTime - startTime) / 1000

    // Calculate the upload speed
    const speedInKbps = (data.length / uploadTime) / 1024
    const speed = speedInKbps.toFixed(2) * 100
    console.log(speed)

    return speed
  })
}

export {
  formatBytes,
  replaceLineBreaks,
  getAvatarImageById,
  validVideoType,
  setUserName,
  awaitTimeout,
  runSpeedTest,
  bytesToKB
}
