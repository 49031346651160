<template>
  <div class="btn-container">
    <div class="btn-cell">
      <b-overlay :show="loading" rounded="sm">
        <div v-if="submissionId && name" class="responding-to">Responding to {{ name }}</div>
        <p v-if="isDisabledRecordVideo" class="text-danger font-weight-bold">If you would like to record a video, please
          use the Safari browser, or you can upload a video here.</p>
        <router-link class="action-icon"
                     custom
                     :to="goToVideoRecordPage()"
                     v-slot="{navigate}"
                     :class="{'hidden':showUploadedVideo}">
          <button @click="navigate" :disabled="isDisabledRecordVideo" type="button">RECORD NEW VIDEO</button>
        </router-link>
        <div>
          <upload-file
              @on-file-select="onFileSelect"
              @on-file-error="onFileSelectError"
              @on-file-upload-start="onFileUploadStart"
              @on-file-upload-success="onFileUploadSuccess"
              @on-file-upload-error="onFileUploadError"
              @UploadProgress="onUploadProgress"
              :max-size="maxVideoSize"
              :auto-upload="false"
              :classroom-id="classroomId"
              :task-id="taskId"
              :submission-id="submissionId"
              ref="upload-video"
              class="form-upload-task"
              :class="{'hidden':showUploadedVideo}"
              accept="video/*"
          />
          <video-uploaded
              v-if="showUploadedVideo"
              :uploaded-file="uploadedFile"
              :classroomId="classroomId"
              :taskId="taskId"
              :submissionId="submissionId"
              @on-upload-try-again="onUploadTryAgain"
              @on-upload-video="onUploadVideo"
              @on-video-error="onVideoError"
              @on-video-loaded-success="videoLoadedSuccess"
          />
          <VideoUploadModal
              :uploaded="uploadedVideo"
              :uploadProgress="uploadProgress"
              @on-video-upload-modal-closed="goToResponseDetailPage"
          />
        </div>
        <div class="upload-file-message" v-if="isInsufficientSpeed && !showUploadedVideo">Please connect your device to a high-speed network. If a high-speed network is not available, we suggest that you record your video here using the 'Record New Video' button.</div>
        <div class="upload-file-message" v-if="videoTimeLimit && !showUploadedVideo">Your video must be no longer than {{ videoTimeLimit }} seconds long and the file must not be larger than {{ formatBytes(this.maxVideoSize) }}.</div>
        <div class="invalid-feedback" v-if="errorVideoType">{{ errorVideoType }}</div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { UploadFile, VideoUploaded } from '@/core/components/Upload'
import { ClassroomService } from '@/core/services'
import { TaskUtils } from '../../utils'
import VideoUploadModal from '@/modules/panel/components/VideoUploadModal'
import { UploadVideoMixin } from '@/core/mixins/UploadVideoMixin'
import { validVideoType, reloadManager, runSpeedTest, formatBytes, bytesToKB } from '@/core/utils'

export default {
  name: 'UploadOrRecordPage',
  components: {
    UploadFile,
    VideoUploaded,
    VideoUploadModal
  },
  mixins: [UploadVideoMixin],
  props: {
    classroomId: {
      type: Number,
      required: true
    },
    taskId: {
      type: Number,
      required: true
    },
    submissionId: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      loading: false,
      name: null,
      showUploadedVideo: false,
      errorVideoType: null,
      taskIsComplete: null,
      responseIsComplete: null,
      uploadProgress: 0,
      videoTimeLimit: 0,
      speed: 25,
      maxVideoSize: 524288000, // bytes
      videoDuration: 0
    }
  },
  methods: {
    formatBytes,
    videoLoadedSuccess (player) {
      this.videoDuration = player.duration
    },
    async init () {
      if (this.submissionId) {
        this.loading = true
        try {
          const response = await ClassroomService.getClassroomPartnerStudentSubmission(this.classroomId, this.submissionId)
          this.responseIsComplete = response.data && !!response.data.responses.length
          this.name = response.data.student.full_name
        } catch (e) {
          const errorMessage = e.message ? e.message : 'Error while loading partner classroom submission.'
          await this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
        } finally {
          this.loading = false
        }
      }
    },
    goToVideoRecordPage () {
      return {
        name: 'VideoRecordPage',
        params: {
          classroomId: this.classroomId,
          taskId: this.taskId,
          submissionId: this.submissionId
        }
      }
    },
    goToResponseDetailPage () {
      this.$router.push({
        name: 'TaskDetailPage',
        params: {
          classroomId: this.classroomId,
          taskId: this.taskId,
          taskAction: this.submissionId ? TaskUtils.ACTION_RESPOND_ACTION : TaskUtils.ACTION_CREATE_TASK
        }
      })
    },
    onFileSelect ({ files }) {
      const validVodFormat = files[0].file.name.indexOf('vob') === -1
      const validMxfFormat = files[0].file.name.indexOf('mxf') === -1

      if (!validVideoType(files[0].file) && validVodFormat && validMxfFormat) {
        this.errorVideoType = 'This file type is not supported on Level Up Village. Please convert it to a supported video file (.3gp, .avi, .m4v, .mkv, .mov, .mp4, .mpg, .mxf, .vob, .webm, or .wmv) and upload it again.'
        return
      }
      this.errorVideoType = null
      this.uploadedFile = files[0]
      this.showUploadedVideo = true
    },
    onFileSelectError (err) {
      this.$bvModal.msgBoxOk(`${err.message}, Please try again`, {
        size: 'sm',
        dialogClass: 'modal-message-box',
        noCloseOnBackdrop: true,
        centered: true
      })
    },
    onFileUploadStart () {
      reloadManager.disable()
      this.$bvModal.show('video-upload-modal')
      this.$emit('on-uploading-video-start')
    },
    async onFileUploadSuccess ({ file }) {
      try {
        const fileData = this.getSelectedFileData()
        const responseSubmission = await ClassroomService.saveSubmission(fileData.fileUrl, this.classroomId, this.taskId, this.submissionId)
        this.$emit('on-uploading-video-success')

        // Send video metadata
        const response = await ClassroomService.fetchMetadataWithPolling(file.name)
        const metaData = {
          video_size: await bytesToKB(fileData.originalVideoSize, 2),
          transcode_video_size: response.data ? response.data : null,
          video_duration: this.videoDuration ? Math.round(this.videoDuration) : 0
        }
        ClassroomService.sendVideoData(metaData, responseSubmission.data.id)
      } catch (e) {
        const error = e.message ? e.message : 'Error while submitting response.'

        this.$bvModal.msgBoxOk(error).then(() => {
          this.$router.push({ name: 'MyTasksPage' })
        })
      }
    },
    async onFileUploadError (e) {
      this.uploadedFile = {}
      this.showUploadedVideo = false
      this.$refs['upload-video'].clearUploadFile()
      this.$bvModal.hide('video-upload-modal')

      this.$emit('on-uploading-video-error')

      const error = e.message ? e.message : 'Error while submitting response.'
      let errorMessage = `${error} Please try again.`
      error === 'Request aborted by user' && (errorMessage = 'Your connection is too slow. Please connect to wifi and try again.')
      await this.$bvModal.msgBoxOk(errorMessage)
    },
    onUploadTryAgain () {
      this.showUploadedVideo = false
      this.uploadedFile = {}

      this.$refs['upload-video'].clearUploadFile()
      this.$refs['upload-video'].openUploadFile()
    },
    onVideoError () {
      this.showUploadedVideo = false
      this.uploadedFile = {}

      this.$refs['upload-video'].clearUploadFile()
    },
    getSelectedFileData () {
      const customData = this.$refs['upload-video'].getSelectedFileData()

      if (!customData) {
        throw new Error('You have not selected a file.')
      }

      return customData
    },
    async onUploadVideo () {
      this.showUploadedVideo = false

      this.loading = true
      try {
        this.$refs['upload-video'].onFileUpload()
      } catch (e) {
        this.$emit('on-uploading-video-error')
        const errorMessage = e.message ? e.message : 'Error while submitting response.'
        await this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
      } finally {
        this.loading = false
      }
    },
    async onUploadProgress (progress) {
      this.uploadProgress = progress
    }
  },
  async created () {
    try {
      await this.init()

      const task = await ClassroomService.task(this.classroomId, this.taskId)

      if (task.data.teacher_submissions) {
        this.taskIsComplete = !!task.data.teacher_submissions.length
      } else {
        this.taskIsComplete = !!task.data.submissions.length
      }

      const submissions = task.data.teacher_submissions ? task.data.teacher_submissions : task.data.submissions

      // If video_processed is code 2 (transcoder failure) a video will be allowed to be uploaded again
      const isVideoProcessError = !!submissions.length && submissions[0]?.video_processed === 2

      if (((this.taskIsComplete && !this.submissionId) || this.responseIsComplete) && !isVideoProcessError) {
        this.$bvModal.msgBoxOk('You already submitted a video for this task.').then(() => {
          this.$router.push({ name: 'MyTasksPage' })
        })
      }
      this.videoTimeLimit = task.data.video_time_limit

      this.speed = await runSpeedTest()
    } catch (e) {
      this.$bvModal.msgBoxOk('Error while loading task information. Please try again.').then(() => {
        location.reload()
      })
    }
  },
  computed: {
    isDisabledRecordVideo () {
      return this.$browserDetect.isChromeIOS
    },
    isInsufficientSpeed () {
      return this.speed <= 10
    }
  },
  watch: {
    submissionId () {
      this.init()
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/core/stylus/variables.styl"
.btn-container
  background-color white
  display table
  height 100%
  width 100%
  text-align center

  .btn-cell
    display table-cell
    vertical-align middle

    .responding-to
      font-size 16px
      font-weight bold
      margin-bottom 15px

    button
      color white
      font-weight 800
      letter-spacing 1px
      font-family "Noto Sans"
      width 200px
      height 31px
      border-radius 4px
      background-color color-blue-100
      border 2px solid color-blue-200
      font-size 14px
      text-transform uppercase
      margin 5px
      padding 0

      &:disabled
        background-color grey
        border solid 1px grey
        cursor not-allowed

.form-upload-task
  &::v-deep
    .btn-input-file
      width 200px
      padding-bottom 3px
      padding-top 3px
      text-transform uppercase

  &.hidden
    display none

.action-icon.hidden
  display none

.invalid-feedback, .upload-file-message
  display block
  margin auto
  margin-top 10px
  font font-opensans-semibold
  font-size 14px
  max-width 500px
  padding 0 15px

.upload-file-message
  color color-black-200
</style>
