<template>
  <div>
    <DashboardHeader :current_tab="'tasks'"></DashboardHeader>
    <div class="dashboard-detail-container" v-if="task">
      <section class="detail-content">
        <back-button :route-params="{classroomId: this.classroomId}" route-name="MyTasksPage"></back-button>
        <div class="details-area">
          <div class="photo" :style="{ backgroundImage: `url('${task.task_thumbnail}')`}"></div>
          <div class="information">
            <div class="information-content">
              <div class="task-name">{{ task.task_name }}</div>
              <div class="topic">{{ task.topic }}</div>
            </div>
            <router-link v-if="!taskIsComplete || isVideoProcessError" class="action-icon" :to="getGoRoute()">
              <div v-if="!hideGoButton">
                <div class="due-date-title"
                    v-if="task.due_date && taskAction === 'createTask'">Due Date: <span>{{setDueDate(task.due_date)}}</span></div>
                <div class="do-task">
                  Go!
                </div>
              </div>
            </router-link>
            <router-link v-else class="action-icon" :to="getViewRoute()">
              <div class="do-task">
                View
              </div>
            </router-link>
          </div>
        </div>
      </section>
      <section v-if="attachmentVideos.length" class="detail-content detail-content--videos">
        <task-video-media v-for="media in attachmentVideos"
                          :key="media.id"
                          :submission="media"
                          :video-name="removeExtension(media.label)"/>
      </section>
      <section class="detail-content">
        <div class="prompt-area">
          <div class="promt-title">PROMPT</div>
          <div v-if="isCreateTask" class="promt-text" v-html="replaceLineBreaks(task.create_prompt)"></div>
          <div v-if="isRespondAction" class="promt-text" v-html="replaceLineBreaks(task.respond_prompt)"></div>
          <div class="task-video-time-limit">TIME: <span>{{ task.video_time_limit }} seconds</span></div>
        </div>
        <div class="downloadable-area">
          <div v-if="task.materials && task.materials.length > 0">
            <div class="d-title">
              STUDENT SUPPORT MATERIALS
              <div class="small-text">*downloadables</div>
            </div>

            <b-list-group horizontal>
              <b-list-group-item
                class="attachment-item"
                v-for="(item,index) in task.materials"
                :key="'task-material-' + index"
                :href="item.url"
                target="_blank"
                :id="'task-material-' + index">
                <thumbnail-attachment :data="item"/>
                <b-tooltip :target="'task-material-' + index" triggers="hover">
                  {{ item.label }}
                </b-tooltip>
                <div class="attachment-item__label">{{ truncateString(item.label, 20) }}</div>
              </b-list-group-item>
            </b-list-group>
          </div>

          <div v-if="task.links && task.links.length > 0">
            <div class="d-title">ADDITIONAL LINKS</div>
            <div v-bind:key="'link-' + index" v-for="(link, index) in task.links" class="link-item">
              <a :href="link.url" target="_blank">{{ link.label }}</a>
            </div>
          </div>
          <div v-if="task.media && task.media.length > 0">
            <div class="d-title">MEDIA</div>

            <b-list-group horizontal>
              <b-list-group-item
                class="attachment-item"
                v-for="(item,index) in task.media"
                :key="'task-media-' + index"
                :href="item.url"
                target="_blank"
                :id="'task-media-' + index">
                <thumbnail-attachment :data="item"/>
                <b-tooltip :target="'task-media-' + index" triggers="hover">
                  {{ item.label }}
                </b-tooltip>
                <div class="attachment-item__label">{{ truncateString(item.label, 20) }}</div>
              </b-list-group-item>
            </b-list-group>
          </div>
        </div>
      </section>
    </div>
    <TaskNotReachableModal/>
    <TaskNotFoundModal/>
  </div>
</template>

<script>
import DashboardHeader from '../../components/Dashboard/DashboardHeader'
import BackButton from '@/core/components/BackButton'
import ThumbnailAttachments from '@/core/components/ThumbnailAttachments'
import { ClassroomService, UploadService } from '@/core/services'
import { TaskUtils } from '../../utils'
import { replaceLineBreaks } from '@/core/utils'
import TaskNotReachableModal from '@/modules/panel/components/TaskNotReachableModal'
import TaskNotFoundModal from '@/modules/panel/components/TaskNotFoundModal'
import moment from 'moment'
import TaskVideoMedia from './TaskVideoMedia'

export default {
  name: 'DashboardDetailPage',
  components: {
    DashboardHeader,
    BackButton,
    'thumbnail-attachment': ThumbnailAttachments,
    TaskNotReachableModal,
    TaskNotFoundModal,
    TaskVideoMedia
  },
  props: {
    classroomId: {
      type: Number,
      required: true
    },
    taskId: {
      type: Number,
      required: true
    },
    taskAction: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      task: null,
      taskIsComplete: false,
      attachmentVideos: []
    }
  },
  computed: {
    hideGoButton: function () {
      return this.task.task_category === 'activity' || this.task.task_category === 'reflection'
    },
    isCreateTask: function () {
      return this.taskAction === TaskUtils.ACTION_CREATE_TASK
    },
    isRespondAction: function () {
      return this.taskAction === TaskUtils.ACTION_RESPOND_ACTION
    },
    isVideoProcessError () {
      // If video_processed is code 2 (transcoder failure) a video will be allowed to be uploaded again
      const submissions = this.task.teacher_submissions ? this.task.teacher_submissions : this.task.submissions
      return !!submissions.length && submissions[0]?.video_processed === 2
    }
  },
  created () {
    ClassroomService.task(this.classroomId, this.taskId).then(async (response) => {
      if (response.success) {
        if (response.data) {
          this.task = response.data
          if (this.isCreateTask) {
            if (this.task.teacher_submissions) {
              this.taskIsComplete = !!this.task.teacher_submissions.length
            } else {
              this.taskIsComplete = !!this.task.submissions.length
            }
          }
          if (this.isRespondAction) {
            const status = TaskUtils.getRespondActionStatus(this.task)
            this.taskIsComplete = status === TaskUtils.STATUS_SUBMITTED || status === TaskUtils.STATUS_APPROVED
          }

          if (this.task.media.length) {
            // filter videos from attachments
            if (this.isCreateTask) {
              this.attachmentVideos = this.task.media.filter((attachment) => attachment?.type === 'transcoder' && (attachment?.video_environment === 'create' || attachment?.video_environment === 'both'))
            }
            if (this.isRespondAction) {
              this.attachmentVideos = this.task.media.filter((attachment) => attachment?.type === 'transcoder' && (attachment?.video_environment === 'respond' || attachment?.video_environment === 'both'))
            }

            // sign the URL of the videos to show them in the attachment list in media
            const mediaSignedUrl = JSON.parse(JSON.stringify(this.task.media))
            for (let i = 0; i < this.task.media.length; i++) {
              const attachment = mediaSignedUrl[i]
              if (attachment.type === 'transcoder') {
                const url = attachment.url.split('/')
                const key = url[url.length - 1]
                attachment.url = await UploadService.getVideo(key)
              }
            }
            this.task.media = mediaSignedUrl
          }
        } else {
          this.$bvModal.show('task-not-found-modal')
        }
      } else {
        if (response.error_code === 18) {
          this.$bvModal.show('task-not-reachable-modal')
        } else {
          const errorMessage = response.message ? response.message : 'Error while trying task.'
          this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
        }
      }
    })
  },
  methods: {
    getGoRoute () {
      if (this.isCreateTask) {
        return {
          name: 'UploadOrRecordPage',
          params: {
            classroomId: this.classroomId,
            taskId: this.task.id
          }
        }
      }
      return {
        name: 'ClassroomDetailPage',
        params: {
          classroomId: this.classroomId,
          taskId: this.task.id
        }
      }
    },
    replaceLineBreaks (text) {
      return replaceLineBreaks(text)
    },
    getViewRoute () {
      if (this.isCreateTask) {
        const submissionId = this.task.teacher_submissions ? this.task.teacher_submissions[0].id : this.task.submissions[0].id
        return {
          name: 'PostDetailPage',
          params: {
            classroomId: this.classroomId,
            postId: submissionId
          }
        }
      }
      if (this.isRespondAction) {
        const responseId = this.task.teacher_responses ? this.task.teacher_responses[0].id : this.task.responses[0].id
        return {
          name: 'ResponseDetailPage',
          params: {
            classroomId: this.classroomId,
            responseId: responseId
          }
        }
      }
      return {
        name: 'ClassroomDetailPage',
        params: {
          classroomId: this.classroomId,
          taskId: this.task.id
        }
      }
    },
    setDueDate (date) {
      return moment(date).format('MM/DD/YYYY')
    },
    truncateString (str, num) {
      return str.length > num ? str.slice(0, num) + '...' : str
    },
    removeExtension (filename) {
      return filename?.substring(0, filename.lastIndexOf('.'))
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"
.dashboard-detail-container
  .detail-content
    max-width 625px
    margin auto

  .detail-content--videos
    max-width 1400px

  .details-area
    min-height 140px
    padding 0 20px
    margin-bottom 30px

    .photo
      display inline-block
      vertical-align top
      height 140px
      width 130px
      background-repeat no-repeat
      background-size cover
      background-position center

    .information
      position relative
      display inline-block
      vertical-align top
      min-height 140px
      width 54%
      padding-top 0
      padding-left 20px

      .due-date-title
        text-transform uppercase
        font-weight bold
        color #3D3D3D
        margin-bottom 10px
        @media (min-width: 992px)
          font-size 18px
        @media (min-width: 768px) and (max-width: 992px)
          font-size 16px
        @media (max-width: 767px)
          font-size 14px

        span
          font-weight 400

      .information-content
        min-height 100px

      .task-name
        font-weight bold
        text-transform uppercase
        color #3D3D3D
        word-break break-word
        @media (min-width: 992px)
          font-size 18px
        @media (min-width: 768px) and (max-width: 992px)
          font-size 16px
        @media (max-width: 767px)
          font-size 14px

      .topic
        @media (min-width: 992px)
          font-size 18px
        @media (min-width: 768px) and (max-width: 992px)
          font-size 16px
        @media (max-width: 767px)
          font-size 14px
        text-transform uppercase
        color #3D3D3D
        word-break break-word

      .do-task
        display inline-block
        margin-top 8px
        background-color color-blue-100
        border solid 2px color-blue-200
        color #FCFCFC
        padding 5px 10px
        border-radius 4px
        line-height 19px
        text-align center
        @media (min-width: 992px)
          font-size 18px
        @media (min-width: 768px) and (max-width: 992px)
          font-size 16px
        @media (max-width: 767px)
          font-size 14px

  .prompt-area
    padding-bottom 20px
    margin 20px
    border-bottom solid 2px #D4D4D4
    word-break break-word

    .promt-title
      text-transform uppercase
      font-weight bold
      color #3D3D3D
      margin-bottom 10px
      @media (min-width: 992px)
        font-size 18px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 16px
      @media (max-width: 767px)
        font-size 14px

    .task-video-time-limit
      text-transform uppercase
      font-weight bold
      color #3D3D3D
      margin-top 10px

      span
        font-weight normal
        text-transform none
        color #3D3D3D
        @media (min-width: 992px)
          font-size 18px
        @media (min-width: 768px) and (max-width: 992px)
          font-size 16px
        @media (max-width: 767px)
          font-size 14px

      @media (min-width: 992px)
        font-size 18px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 16px
      @media (max-width: 767px)
        font-size 14px

    .promt-text
      color #3D3D3D
      @media (min-width: 992px)
        font-size 18px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 16px
      @media (max-width: 767px)
        font-size 14px

  .downloadable-area
    margin 20px

    .d-title
      color #3D3D3D
      font-weight bold
      margin-bottom 10px
      margin-top 10px
      @media (min-width: 992px)
        font-size 18px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 16px
      @media (max-width: 767px)
        font-size 14px

    .small-text
      font-weight 400
      @media (min-width: 992px)
        font-size 14px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 13px
      @media (max-width: 767px)
        font-size 12px

    .link-item
      font-weight bold
      color #1968FF
      text-transform uppercase
      margin 8px 10px
      text-decoration underline
      word-break break-word
      @media (min-width: 992px)
        font-size 18px
      @media (min-width: 768px) and (max-width: 992px)
        font-size 16px
      @media (max-width: 767px)
        font-size 14px

.list-group.list-group-horizontal
  overflow-x auto

.list-group-item-action.attachment-item
  width unset
  display flex
  align-items center
  flex-direction column
  margin-right 10px
  border 2px solid #4b59ac !important

  .attachment-item__label
    margin-top 6px
    font-weight 800
    text-transform uppercase
    font-size 14px
</style>
