<template>
  <div class="record-container" :class="'recorded'">
    <div class="video-container">
      <video-custom
        v-if="!loading"
        :src="uploadedFile.objectURL"
        :objectURL="true"
        :show-temporal-ios-solution-message="showTemporalIosSolutionMessage"
        :is-response-video="!!$route.params.submissionId"
        @on-video-play="onVideoPlay"
        @on-video-pause="onVideoPause"
        @on-video-ended="onVideoEnded"
        @on-video-loaded="onVideoLoaded"
        @on-video-error="onVideoError"/>
    </div>
    <div class="submit-recording" :class="'recorded'">
      <div role="button" class="submit review" @click.prevent="reviewPrompt">
        REVIEW PROMPT
      </div>
      <div role="button" class="submit" @click.prevent="submitVideo">
        SUBMIT
      </div>
      <div role="button" class="submit delete" @click.prevent="$emit('on-upload-try-again')">
        DELETE & TRY AGAIN
      </div>
    </div>
    <TaskPromptModal :videoTimeLimit="time.maxLength" :prompt="taskPrompt"/>
    <b-overlay no-wrap :show="loading"/>
  </div>
</template>

<script>
import { ClassroomService } from '@/core/services'
import TaskPromptModal from '@/modules/panel/components/TaskPromptModal'
import VideoCustom from '@/core/components/video/VideoCustom'

export default {
  name: 'VideoUploaded',
  components: {
    TaskPromptModal,
    VideoCustom
  },
  props: {
    classroomId: {
      type: Number,
      required: true
    },
    taskId: {
      type: Number,
      required: true
    },
    submissionId: {
      type: Number,
      required: false
    },
    uploadedFile: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      time: {
        videoTimeLimit: 0,
        maxLength: 0,
        currentTime: 0,
        minutes: 0,
        seconds: 0
      },
      isRecording: false,
      isRecorded: false,
      player: null,
      loading: true,
      taskPrompt: null,
      videoTimeLimit: 0,
      downloadedFileName: '',
      url: '',
      showTemporalIosSolutionMessage: false
    }
  },
  methods: {
    async init () {
      this.loading = true
      try {
        const response = await ClassroomService.task(this.classroomId, this.taskId)
        this.taskPrompt = this.submissionId ? response.data.respond_prompt : response.data.create_prompt
        this.videoTimeLimit = response.data.video_time_limit
        const videoTimeLimit = response.data.video_time_limit

        this.time.videoTimeLimit = videoTimeLimit
        this.time.maxLength = videoTimeLimit
        this.time.currentTime = videoTimeLimit
      } catch (e) {
        const errorMessage = e.message ? e.message : 'Error while loading task information.'
        await this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
      } finally {
        this.loading = false
      }
    },
    reviewPrompt () {
      this.$bvModal.show('task-prompt-modal')
    },
    submitVideo () {
      this.$emit('on-upload-video')
    },
    onVideoPlay () {
    },
    onVideoPause () {
    },
    onVideoEnded () {
    },
    async onVideoLoaded (player) {
      if (player.duration < 1) {
        await this.$bvModal.msgBoxOk('The selected video must be at least one second long.  Please try again.')
        this.$emit('on-video-error')
      } else if (parseInt(player.duration) > this.videoTimeLimit) {
        await this.$bvModal.msgBoxOk(`The selected video must be maximum of ${this.videoTimeLimit} seconds long.  Please try again.`)
        this.$emit('on-video-error')
      } else {
        this.$emit('on-video-loaded-success', player)
      }
    },
    async onVideoError (e) {
      const errorMessage = e.message ? e.message : 'Error while load video.'
      // TODO: temporal solution for issue with ios, when is fixed delete the "if"  and let the content as it
      // await this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
      // this.$emit('on-video-error')
      if (errorMessage !== 'Error while load unplayable video.') {
        await this.$bvModal.msgBoxOk(`${errorMessage} Please try again.`)
        this.$emit('on-video-error')
      } else {
        this.showTemporalIosSolutionMessage = true
      }
    }
  },
  mounted () {
    this.init()
  },
  watch: {
    classroomId () {
      this.init()
    },
    taskId () {
      this.init()
    },
    time: {
      deep: true,
      immediate: true,
      handler: function ({ currentTime }) {
        this.time.minutes = Math.floor(currentTime / 60)
        this.time.seconds = currentTime - this.time.minutes * 60
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/core/stylus/variables.styl"
.myVideo-dimensions.vjs-fluid
  padding-top unset

.video-js
  height 100%
  width 100%

.record-container
  height calc(100% - 120px)

  &.recorded
    height calc(100% - 213px)

  .timer-container-bg
    background-color color-purple-100

  .timer-container
    max-width 720px
    margin auto
    height 60px
    color color-blue-100
    font-family "Open Sans"
    font-size 12px
    font-weight bolder
    padding-top 15px

    .timer
      text-align center

    .timer-title
      display inline-block
      margin-right 10px

    .timer-counter
      display inline-block
      vertical-align middle
      width 25px
      margin-left 8px

  .bar
    border 2px solid
    height 12px
    margin-left 30px
    padding 2px
    border-radius 3px
    width calc(100% - 90px)
    display inline-block
    vertical-align middle

    .fill
      border-radius 3px
      border solid 2px
      height 4px
      width 80%

  .video-container
    max-width 720px
    height 100%
    margin auto

  .submit-recording
    width 100%
    height auto
    background-color color-purple-100
    text-align center
    padding-top 20px
    padding-bottom 20px

    .submit
      cursor pointer
      margin auto
      width 114px
      height 31px
      line-height 31px
      background-color color-blue-100
      color white
      font-size 12px
      font-weight bold
      border-radius 4px
      letter-spacing 2px
      margin-top 10px

      &.delete
        width 226px

      &.review
        width 226px
</style>
