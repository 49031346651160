import moment from 'moment'
import { ClassroomMixins } from './classroom'

export const ClassroomExchangeMixins = {
  mixins: [ClassroomMixins],
  computed: {
    classroomExchangeExpired () {
      const currentTimestamp = moment()
      return moment(currentTimestamp).isAfter(this.classroom.expire_at)
    },
    isMainClassroom () {
      return !!this.classroom.class_pairing?.id
    }
  }
}
