import { render, staticRenderFns } from "./BackButton.vue?vue&type=template&id=79e77b88&scoped=true"
import script from "./BackButton.vue?vue&type=script&lang=js"
export * from "./BackButton.vue?vue&type=script&lang=js"
import style0 from "./BackButton.vue?vue&type=style&index=0&id=79e77b88&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79e77b88",
  null
  
)

export default component.exports